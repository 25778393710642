/*
 *  ======================================= 
 *  GLOBAL | _global
 *  =======================================  
 */

// Wstaw tutaj link do dodatkowych czcionek (np. Google Font)
@import url('https://fonts.googleapis.com/css?family=Cuprum:400,700&subset=latin-ext');

html, body{
	color: $black;
	font-family: 'Cuprum', sans-serif;
	font-size: 20px;
	font-weight: 400;
	min-width: 320px;
	height: 100vh;
}

html {
   -ms-overflow-style: scrollbar;
}

a,.btn,button,input[type="button"],input[type="submit"],textarea:focus,input:focus{
  outline:none !important;
  box-shadow: unset !important;
}

a{
	color: $orange;
	transition: color .3s ease;
	&:hover, &:focus, &:active{
		color: $black;
		text-decoration: none;
		transition: color .3s ease;
	}
}

ul:not([class]){
	list-style-type: none;
	padding-left: 0;
	li{
		padding-left: 20px;
		position: relative;
		&:before{
			color: #666;
			content: "•";
			position: absolute;
			left: 0;
			transform: scale(1.2);
		}
	}
}

ol:not([class]){
  list-style-type: none;
	padding-left: 0;
  li {
    counter-increment: step-counter;
    padding-left: 20px;
    position: relative;
    &:before {
      content: counter(step-counter) '.';
      position: absolute;
      left: 0;
    }
  }
}

.cl{
	clear: both; 
	width: 100%;
	height: 1px;
	line-height: 1px;
	display: block;
}

.image-to-left{
	float: left;
	margin: 0 20px 15px 0;
}

.image-to-right{
	float: right;
	margin: 0 0 15px 20px;
}

.layout-container{
	padding-left: 0;
	padding-right: 0;
}

.cke_editable{
	padding: 15px 30px;
	img{
    display: block;
    max-width: 100%;
    height: auto;
	}
}

.cke_widget_wrapper{
	margin-left: -15px;
	margin-right: -15px;
}

.cke_widget_element{
	padding-left: 15px;
	padding-right: 15px;
}

table{
	thead, tbody{
		th, td{
			padding: 5px 10px;
		}
	}
}

.global{
	&-flexcontent{
		display: flex;
		flex-wrap: wrap;
		&:before, &:after{
			display: none;
		}
	}
	&-float--100{
		float: left;
		width: 100%;
	}
	&-clear{
		clear: both;
	}
	&-title{
		color: $black;
		font-size: 30px;
		margin: 35px 0;
		text-transform: uppercase;
	}
	&-orangeframe{
		background-color: $orange;
		color: $white;
		font-size: 24px;
		margin-top: 40px;
		padding: 28px 15px;
	}
	&-display--inlineblock{
		display: inline-block;
	}
	&-position--relative{
		position: relative;
	}
}

//BUTTONS

.button{
	&-default{
		background-color: $orange;
		border-bottom: 2px solid #c9591a;
		border-radius: 5px;
		color: $white;
		display: inline-block;
		font-size: 14px;
		height: 40px;
		line-height: 42px;
		min-width: 170px;
		text-align: center;
	  text-transform: uppercase;
		transition: all .2s linear;
		&:hover{
			background-color: darken($orange, 10%);
			color: $white;
			transition: all .2s linear;
		}
	}
	
	&-textandicon{
		border-radius: 5px;
		display: inline-block;
		overflow: hidden;
		position: relative;
	  text-transform: uppercase;
		width: 100%;
		i{
			vertical-align: text-top;
		}
		.button-icon{
			background-color: #c55c21;
			border-bottom: 2px solid #a84e1c;
			color: $white;
			display: inline-block;
			font-size: 18px;
			height: 40px;
			line-height: 42px;
			position: relative;
			text-align: center;
			transition: all .2s linear;
			width: 40px;
		}
		.button-text{
			background-color: $orange;
			border-bottom: 2px solid #c9591a;
			color: $white;
			display: inline-block;
			font-size: 14px;
			height: 40px;
			line-height: 42px;
			position: relative;
			text-align: center;
			transition: all .2s linear;
			width: calc(100% - 40px);
			vertical-align: top;
		}
		&:hover{
			.button-icon{
				background-color: darken(#c55c21, 10%);
			}
			.button-text{
				background-color: darken($orange, 10%);
			}
		}
	}
	
	&-download{
		background-color: $orange;
		color: $white;
		display: inline-block;
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 30px;
		padding: 13.5px 20px;
		padding-right: 55px;
		position: relative;
		transition: color .2s linear, background-color .2s linear;
		width: 100%;
		i{
			color: $black2;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			transition: color .2s linear;
		}
		&:hover{
			background-color: $black2;
			color: $white;
			transition: color .2s linear, background-color .2s linear;
			i{
				color: $orange;
				transition: color .2s linear;
			}
		}
	}
}

//HAMBURGER

.hamburger{
	box-shadow: 0 0 5px 0 rgba($black, 0.3) !important;
	border-radius: 5px;
	position: fixed;
	height: 52px;
	right: 15px;
	top: 20px;
  padding: 15px 15px;
  //display: inline-block;
	display: none;
  cursor: pointer;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: $orange;
  border: 0;
	border-bottom: 2px solid #c9591a;
  margin: 0;
  overflow: visible;
  &:hover {
		background-color: darken($orange, 10%);
		transition-property: all;
	}
	&-box{
		width: 40px;
		height: 24px;
		display: inline-block;
		position: relative;
	}
	&-inner{
		display: block;
		top: 50%;
		margin-top: -2px;
	}
	&-inner, &-inner:before, &-inner:after{
		width: 40px;
		height: 2px;
		background-color: $white;
		border-radius: 4px;
		//box-shadow: 0px 0px 8px 0px rgba($white, 0.4) !important;
		position: absolute;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;
	}
	&-inner:before, &-inner:after{
		content: "";
		display: block;
	}
	&-inner:before{
		top: -10px;
	}
	&-inner:after{
		bottom: -10px;
	}
}

.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

//HEADER

.header{
	background-color: $white;
	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
	height: 100px;
	position: relative;
	width: 100%;
	z-index: 5;
	&-logo{
		display: inline-block;
		height: 100px;
		position: relative;
		&-link{
			display: inline-block;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			transition: opacity .2s linear;
			&.aside-active{
				opacity: 0;
				transition: opacity .2s linear;
			}
		}
		&-image{
			height: 50px;
			transition: height .3s ease;
			width: auto;
		}
	}
	&-menu{
		&-desktop{
			display: inline-block;
			padding: 0;
			list-style-type: none;
			margin-bottom: 0;
			position: relative;
			text-align: right;
			> li{
				display: inline-block;
				margin-left: 35px;
				position: relative;
				vertical-align: top;
				> a{
					color: $black2;
					font-size: 17px;
					font-weight: 700;
					display: block;
					//height: 100px;
					position: relative;
					margin: 38px 0px;
					transition: color .2s linear;
					text-decoration: none;
					text-transform: uppercase;
					i{
						font-size: 6px;
						margin-left: 5px;
						position: relative;
						&:before{
							vertical-align: super;
						}
					}
					&:before{
						content: "";
						position: absolute;
						width: 100%;
						height: 1px;
						bottom: -6px;
						left: 0;
						background-color: $orange;
						visibility: hidden;
						transform: scaleX(0);
						transition: all 0.3s ease-in-out 0s;
					}
				}
				&:last-child{
					a{
						background-color: $orange;
						//border-bottom: 2px solid #c9591a;
						border-radius: 5px;
						color: $white;
						display: inline-block;
						font-size: 14px;
						font-weight: 400;
						height: 40px;
						line-height: 40px;
						margin: 30px 0 0;
						overflow: hidden;
						padding: 0 18px 0 0;
						position: relative;
						text-align: center;
						transition: all .2s linear;
						&:before{
							background-color: #c55c21;
							bottom: 0;
							content: "\e925";
							border-bottom: 2px solid #a84e1c;
							color: $white;
							display: inline-block;
							font-family: 'dakar';
							font-size: 18px;
							height: 40px;
							margin-right: 18px;
							position: relative;
							text-align: center;
							transform: none;
							transition: all .2s linear;
							width: 40px;
							vertical-align: sub;
							visibility: visible;
							z-index: 1;
						}
						&:after{
							border-bottom: 2px solid #c9591a;
							bottom: 0;
							content: '';
							height: 2px;
							position: absolute;
							right: 0;
							transform: translateZ(0);
							width: 100%;
							z-index: 0;
						}
						&:hover{
							background-color: darken($orange, 10%);
							color: $white;
							&:before{
								background-color: darken(#c55c21, 10%);
							}
						}
					}
				}
				ul{
					display: block;
					padding-left: 0;
					position: absolute;
					left: 0;
					top: calc(100% - 32px);
					z-index: 1;
					li{
						overflow:hidden;
						height:0;
						transition:height 200ms ease-in;
						padding-left: 0;
						display: block;
						a{
							background-color: rgba($orange, 1);
							border-bottom: 1px solid $white;
							color: $white;
							display: block;
							font-weight: 700;
							font-size: 15px;
							height: 38px;
							padding: 8px 9px;
							text-align: left;
							text-transform: uppercase;
							white-space: nowrap;
							width: 100%;
						}
						&:before{
							display: none;
						}
						&:last-child{
							a{
								border-bottom-color: $orange;
							}
						}
					}
				}
				&:hover{
					> a{
						color: $orange;
						&:before{
							visibility: visible;
							transform: scaleX(1);
						}
					}
					> ul{
						li{
							height: 38px;
							a{
								&:hover, &:focus{
									background-color: darken($orange, 10%);
									color: $white;
								}
							}
							&.active{
								> a{
									background-color: darken($orange, 10%);
									color: $white;
								}
							}
						}
					}
				}
				&:last-child{
					&:hover{
						a{
							color: $white;
						}
					}
				}
				&.active{
					> a{
						color: $orange;
						&:before{
							visibility: visible;
							transform: scaleX(1);
						}
					}
				}
			}
		}
	}
	&-button{
    margin-top: 27px;
	}
}

//ASIDE
.aside{
	background-color: rgba($white, 1);
	box-shadow: 0 0 30px 0 rgba($black, 0.7);
	height: 100vh;
	min-width: 220px;
	position: fixed;
	top: 0;
	transition: transform .2s linear;
	transform: translateX(-260px);
	z-index: 1000;
	&-logo{
		height: 100px;
		margin: auto;
		text-align: center;
		&-link{
			display: inline-block;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
		&-image{
			height: 50px;
			width: auto;
		}
	}
	&-container{
		height: calc(100vh - 100px);
		margin-top: 100px;
		overflow: hidden;
		position: absolute;
		text-align: center;
    top: 0;
    width: 100%;
	}
	&-menu{
		margin-bottom: 20px;
		padding-left: 0;
		list-style-type: none;
		text-align: center;
		> li{
			margin-bottom: 0px;
			position: relative;
			&:before{
				content: '';
				position: absolute;
				background-color: rgba(60, 59, 58, 0.2);
				bottom: -0.5px;
				left: 30%;
				width: 40%;
				height: 1px;
			}
			&:last-child{
				a{
					background-color: $orange;
					//border-bottom: 2px solid #c9591a;
					border-radius: 5px;
					color: $white;
					display: inline-block;
					font-size: 14px;
					font-weight: 400;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					overflow: hidden;
					padding: 0 5px 0 0;
					position: relative;
					text-align: center;
					transition: all .2s linear;
					vertical-align: middle;
					&:before{
						background-color: #c55c21;
						content: "\e925";
						border-bottom: 2px solid #a84e1c;
						color: $white;
						display: inline-block;
						font-family: 'dakar';
						font-size: 18px;
						height: 40px;
						margin-right: 5px;
						position: relative;
						text-align: center;
						transition: all .2s linear;
						width: 35px;
						vertical-align: sub;
						z-index: 1;
					}
					&:after{
						border-bottom: 2px solid #c9591a;
						bottom: 0;
						content: '';
						height: 2px;
						position: absolute;
						right: 0;
						width: 100%;
						z-index: 0;
					}
					&:hover{
						background-color: darken($orange, 10%);
						color: $white;
						&:before{
							background-color: darken(#c55c21, 10%);
						}
					}
				}
				&:before{
					display: none;
				}
			}
			> a{
				color: $black;
				display: block;
				font-size: 16px;
				font-weight: 700;
				padding: 10px 0;
				transition: all .3s ease;
				text-transform: uppercase;
				i{
					display: none;
				}
				&:hover{
					color: $orange;
				}
			}
			&.active{
				> a{
					color: $orange;
				}
			}
			ul{
				margin-top: -10px;
				li{
					padding-left: 0;
					&:before{
						display: none;
					}
					&.active{
						> a{
							color: $orange;
						}
					}
					a{
						color: #444444;
						display: block;
						font-size: 14px;
						font-weight: 700;
						padding: 6px 0;
						transition: all .3s ease;
						text-transform: uppercase;
						&:hover{
							color: $orange;
						}
					}
				}
			}
		}
	}
}

//MAIN

main, section{
	float: left;
	width: 100%;
}

//FOOTER

.footer{
	color: $white;
	float: left;
	font-size: 16px;
	width: 100%;
	a{
		color: $white;
		&:hover{
			color: $orange;
		}
		&.realization{
			color: $orange;
			&:hover{
				color: darken($orange, 10%);
			}
		}
	}
	&-menu{
		columns: 2;
		list-style-type: none;
		padding-left: 0;
		position: relative;
		li{
			padding-left: 10px;
			position: relative;
			a{
				i{
					display: none;
				}
			}
			ul{
				display: none;
			}
			&:before{
				content: '-';
				left: 0;
				position: absolute;
			}
			&.active{
				a{
					color: $orange;
					text-decoration: underline;
				}
			}
		}
	}
	&-top{
		background-color: $black;
		padding: 39px 0;
		p, ul{
			margin-bottom: 0;
		}
	}
	&-bottom{
		background-color: #101010;
		padding: 16.5px 0;
		p{
			margin-bottom: 0;
		}
	}
}