@font-face {
  font-family: 'dakar';
  src:  url('../fonts/dakar.eot?aq05h9');
  src:  url('../fonts/dakar.eot?aq05h9#iefix') format('embedded-opentype'),
    url('../fonts/dakar.ttf?aq05h9') format('truetype'),
    url('../fonts/dakar.woff?aq05h9') format('woff'),
    url('../fonts/dakar.svg?aq05h9#dakar') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dakar' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-przegladarka:before {
  content: "\e91e";
}
.icon-notatnik:before {
  content: "\e91f";
}
.icon-domena:before {
  content: "\e920";
}
.icon-logo:before {
  content: "\e921";
}
.icon-udostepnionyfolder:before {
  content: "\e922";
}
.icon-socialmedia:before {
  content: "\e923";
}
.icon-pracazespolowa:before {
  content: "\e924";
}
.icon-klodka:before {
  content: "\e925";
}
.icon-lewo:before {
  content: "\e927";
}
.icon-prawo:before {
  content: "\e928";
}
.icon-dol:before {
  content: "\e900";
}
.icon-check:before {
  content: "\e929";
}
.icon-pobierz:before {
  content: "\e901";
}