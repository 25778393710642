/*
 *  ======================================= 
 *  CONTACT | _contact
 *  =======================================  
 */

.contact{
	&-map{
		height: 425px;
	}
	&-content{
		a{
			color: $black;
			&:hover{
				color: $orange;
			}
		}
	}
}