/*
 *  ======================================= 
 *  HOME PAGE | _home_page
 *  =======================================  
 */

.homepage{
	&-slider{
		color: $black;
		&-hash{
			position: absolute;
			top: 0;
		}
		.owl-item{
			touch-action: auto !important;
		}
		&-holder{
			float: left;
			position: relative;
			width: 100%;
		}
		&-controls{
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			pointer-events: none;
			width: 100%;
			z-index: 2;
			&-holder{
				left: 0;
				pointer-events: none;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				width: 100%;
				z-index: 2;
			}
			i{
				background-color: $black2;
				border-radius: 50%;
				color: $white;
				display: inline-block;
				font-size: 16px;
				padding: 17px 20px;
				transition: background-color .2s linear, color .2s linear;
				&:hover{
					background-color: $orange;
					color: $white;
					transition: background-color .2s linear, color .2s linear;
				}
			}
			.owl-prev, .owl-next{
				background-color: transparent;
				border: 0;
				cursor: pointer;
				height: 50px;
				pointer-events: all;
				position: absolute;
				//top: calc(50% - 20px);
				transform: translateY(-50%);
			}
			.owl-prev{
				left: 10px;
			}
			.owl-next{
				right: 10px;
			}
		}
		&-dots{
			//pointer-events: all;
			&-holder{
				left: 0;
				pointer-events: none;
				position: absolute;
				bottom: 10px;
				right: 0;
				width: 100%;
				z-index: 2;
			}
			.owl-dot{
				background-color: transparent;
				border: 0;
				cursor: pointer;
				display: inline-block;
				pointer-events: all;
				padding: 0;
    		zoom: 1;
				span {
					background-color: rgba($orange, 0);
					border: 3px solid $black2;
					width: 15px;
					height: 15px;
					margin: 10px 5px 10px 5px;
					display: block;
					position: relative;
					-webkit-backface-visibility: visible;
					//transition: background-color .2s linear, color .2s linear;
					border-radius: 50%;
				}
				&.active span, &:hover span {
					background-color: rgba($orange, 1);
					border: 3px solid $orange;
					border-radius: 50%;
					//transition: background-color .2s linear, color .2s linear;
					box-shadow: 0 3px 0 0px #bd5419;
					height: 12px;
					top: -3px;
				}
			}
		}
		.item{
			height: 550px;
			position: relative;
			&-background{
				background-position: center center;
				background-size: cover;
				height: 100%;
				position: absolute;
				width: 100%;
			}
			&-slogan{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 100%;
			}
			&-text{
				background-color: rgba($white, 0.8);
				//box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6) !important;
				display: inline-block;
				line-height: 1.2;
				padding: 25px 25px;
				p, ul{
					//margin-bottom: 20px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			&-button{
				margin-top: 35px;
			}
		}
	}
	
	&-offer{
		&-box{
			display: block;
			margin-bottom: 50px;
			position: relative;
			.imagebox{
				background-color: $black;
				position: relative;
				&-button{
					background-color: $black2;
					border: 2px solid $orange;
					border-radius: 5px;
					color: $white;
					font-size: 14px;
					height: 40px;
					left: 50%;
					opacity: 0;
					padding: 8px 0;
					position: absolute;
					text-align: center;
					text-transform: uppercase;
					top: 75%;
					transform: translate(-50%, -50%);
					transition: all .15s linear;
					width: 170px;
					z-index: 1;
				}
				&-image{
					background-size: cover;
					left: 0;
					height: 100%;
					position: absolute;
					transition: opacity .2s linear .2s;
					width: 100%;
					z-index: 0;
				}
				&-ratio{
					padding-bottom: 69.7%;
				}
			}
			.caption{
				background-color: $white;
				color: $black;
				font-size: 20px;
				padding: 13.5px 0;
				text-align: center;
				transition: all .2s linear;
			}
			&:after{
				content: '';
				box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6) !important;
				left: 0;
				height: 100%;
				opacity: 0;
				position: absolute;
				top: 0;
				transition: opacity .2s ease-in-out;
				width: 100%;
			}
			&:hover{
				&:after{
					opacity: 1;
				}
				.imagebox-button{
					opacity: 1;
					transition: all .15s linear .2s;
					top: 50%;
				}
				.imagebox-image{
					opacity: 0.5;
					transition: opacity .2s linear;
				}
				.caption{
					background-color: $orange;
					color: $white;
					transition: all .2s linear;
				}
			}
		}
		&-frame{
			background-color: $white;
			border: 15px solid rgba($black, 0.1);
			margin-bottom: 50px;
			.frame{
				&-title{
					color: $black;
					font-size: 30px;
					margin-bottom: 38px;
					margin-top: 30px;
					text-align: center;
					a{
						color: $orange;
						text-decoration: underline;
						&:hover{
							color: darken($orange, 10%);
						}
					}
				}
				&-item{
					margin-bottom: 30px;
					text-align: center;
					&-icon{
						background-color: $orange;
						border-radius: 50%;
						color: $white;
						display: inline-block;
						font-size: 20px;
						height: 50px;
						margin-right: 10px;
						padding: 15px 0; 
						text-align: center;
						width: 50px;
					}
					&-text{
						display: inline-block;
						font-size: 20px;
						vertical-align: text-bottom;
					}
				}
			}
		}
	}
	
	&-profits{
		background-color: $grey;
		padding-bottom: 6px;
		text-align: center;
		&.background{
			&-white{
				background-color: $white;
			}
		}
		&-title{
			margin: 32px 0 24px;
		}
		&-icon{
			color: $orange;
			display: inline-block;
			font-size: 55px;
			margin-top: 15px;
			margin-bottom: 25px;
		}
		&-text{
			color: $black;
			font-size: 20px;
			line-height: 1.2;
			margin-bottom: 30px;
		}
	}
	
	&-novelties{
		&-box{
			margin-bottom: 20px;
			.imagebox{
				background-color: $black;
				display: block;
				margin-bottom: 25px;
				position: relative;
				&-button{
					background-color: $black2;
					border: 2px solid $orange;
					border-radius: 5px;
					color: $white;
					font-size: 14px;
					height: 40px;
					left: 50%;
					opacity: 0;
					padding: 8px 0;
					position: absolute;
					text-align: center;
					text-transform: uppercase;
					top: 75%;
					transform: translate(-50%, -50%);
					transition: all .15s linear;
					width: 170px;
					z-index: 1;
				}
				&-image{
					background-size: cover;
					left: 0;
					height: 100%;
					position: absolute;
					transition: opacity .2s linear .2s;
					width: 100%;
					z-index: 0;
				}
				&-ratio{
					padding-bottom: 84.85%;
				}
				&:hover{
					.imagebox-button{
						opacity: 1;
						transition: all .15s linear .2s;
						top: 50%;
					}
					.imagebox-image{
						opacity: 0.5;
						transition: opacity .2s linear;
					}
				}
			}
			.novelty{
				&-title{
					color: $black;
					display: inline-block;
					font-size: 20px;
					margin-bottom: 23px;
					&:hover{
						color: $orange;
					}
				}
				&-text{
					color: $grey2;
					font-size: 16px;
				}
			}
		}
		&-row{
			margin-top: 50px;
		}
		&-tools{
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			top: -50px;
			z-index: 1;
		}
		&-frame{
			background-color: $white;
			border: 15px solid rgba($black, 0.1);
			margin-bottom: 60px;
			&.frame-right{
				text-align: right;
				.frame-title, .frame-text{
					margin-right: 25px;
					margin-left: 0; 
				}
				.frame-button{
					margin-left: 30px;
				}
			}
			.frame{
				&-title{
					color: $black;
					font-size: 30px;
					margin-bottom: 10px;
					margin-left: 25px;
					margin-top: 20px;
				}
				&-text{
					color: $grey3;
					font-size: 20px;
					line-height: 1.2;
					margin-bottom: 10px;
					margin-left: 25px; 
				}
				&-button{
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
	
	&-logos{
		background-color: $grey;
		.item{
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 100% auto;
			filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
			-webkit-filter: grayscale(100%);
			transition: all .2s linear;
			&-ratio{
				padding-bottom: 50%;
			}
			&:hover{
				filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;f…0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
				-webkit-filter: grayscale(0%); 
			}
		}
		&-holder{
			float: left;
			padding: 18.125px 0px;
			position: relative;
			width: 100%;
		}
		&-controls{
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			pointer-events: none;
			width: 100%;
			z-index: 2;
			i{
				background-color: $black2;
				border-radius: 50%;
				color: $white;
				display: inline-block;
				font-size: 16px;
				padding: 17px 20px;
				transition: background-color .2s linear, color .2s linear;
				&:hover{
					background-color: $orange;
					color: $white;
					transition: background-color .2s linear, color .2s linear;
				}
			}
			.owl-prev, .owl-next{
				background-color: transparent;
				border: 0;
				cursor: pointer;
				height: 50px;
				pointer-events: all;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
			.owl-prev{
				left: 0px;
			}
			.owl-next{
				right: 0px;
			}
		}
	}
}