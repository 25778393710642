/*
 *  ======================================= 
 *  SUBPAGE | _subpage
 *  =======================================  
 */

.subpage{
	&-content{
		img{
			display: block;
			max-width: 100%;
			height: auto;
		}
	}
	
	&-offer{
		&-box{
			display: block;
			//margin-bottom: 50px;
			position: relative;
			.imagebox{
				background-color: $black;
				position: relative;
				&-button{
					background-color: $black2;
					border: 2px solid $orange;
					border-radius: 5px;
					color: $white;
					font-size: 14px;
					height: 40px;
					left: 50%;
					opacity: 0;
					padding: 8px 0;
					position: absolute;
					text-align: center;
					text-transform: uppercase;
					top: 75%;
					transform: translate(-50%, -50%);
					transition: all .15s linear;
					width: 170px;
					z-index: 1;
				}
				&-image{
					background-size: cover;
					left: 0;
					height: 100%;
					position: absolute;
					transition: opacity .2s linear .2s;
					width: 100%;
					z-index: 0;
				}
				&-ratio{
					padding-bottom: 51%;
				}
			}
			.caption{
				background-color: $orange;
				color: $white;
				font-size: 20px;
				padding: 13.5px 0;
				text-align: center;
				//transition: all .2s linear;
			}
			&:after{
				content: '';
				box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6) !important;
				left: 0;
				height: 100%;
				opacity: 0;
				position: absolute;
				top: 0;
				transition: opacity .2s ease-in-out;
				width: 100%;
			}
			&:hover{
				&:after{
					opacity: 1;
				}
				.imagebox-button{
					opacity: 1;
					transition: all .15s linear .2s;
					top: 50%;
				}
				.imagebox-image{
					opacity: 0.5;
					transition: opacity .2s linear;
				}
			}
		}
	}
	
	&-profits{
		background-color: $white;
		padding-bottom: 12px;
		text-align: center;
		&-title{
			margin: 32px 0 24px;
		}
		&-icon{
			border: 2px solid $orange;
			border-radius: 50%;
			color: $black;
			display: inline-block;
			font-size: 20px;
			margin-top: 15px;
			margin-bottom: 25px;
			padding: 13px 12.5px;
		}
		&-text{
			color: $black;
			font-size: 20px;
			line-height: 1.2;
			margin-bottom: 30px;
		}
	}
	
	&-files{
		padding-top: 35px;
	}
	
	&-spacer{
		padding-bottom: 35px;
	}
}